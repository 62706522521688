<template>
  <div>
    <v-container>
      <v-container>
        <v-row>
          <v-col cols="12">
            <s-toolbar
              :color="'#244093'"
              dark
              label="Reporte de Ventas RE"
              class="tran"
            ></s-toolbar>
            <v-card>
              <v-row
                justify="start"
                style="margin-top: 4px; margin-left: 10px; margin: auto"
              >
                <v-col sm="6" cols="6" lg="2" md="3">
                  <s-select-definition
                    :def="1215"
                    v-model="objMounth"
                    return-object
                    label="Seleccione periodo"
                  ></s-select-definition>
                </v-col>

                <v-col sm="4" cols="6" lg="2" md="2" class="mt-4 pl-0">
                  <v-btn
                    width="100%"
                    rounded
                    :color="'info'"
                    small
                    @click="run()"
                    >Buscar</v-btn
                  >
                </v-col>
                <v-col sm="4" cols="6" lg="2" md="2" class="mt-4 pl-0">
                  <v-btn
                    @click="downloadOne()"
                    rounded
                    :color="'warning'"
                    small
                    width="100%"
                    :disabled="disabledBtnDownload"
                  >
                    Descargar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-dialog
          v-model="processing"
          :persistent="messageProcessing.length == 0"
          width="400"
        >
          <v-card color="primary" dark>
            <v-card-text v-if="messageProcessing.length == 0">
              Por favor espere
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
              <div class="text--white pt-4">
                Error al realizar busqueda <br />
                {{ messageProcessing }}
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
      <v-container>
        <v-tabs v-model="currentItem">
          <v-tab href="#DashboardCv"> Dashboard CV</v-tab>
          <v-tab href="#SalesCV"> Vendidos</v-tab>
          <v-tab href="#SalesCVINFO">Vendidos Info.</v-tab>
          <v-tab href="#DashboardRe"> Dashboard RE</v-tab>
          <v-tab href="#SalesRE"> Resueltos</v-tab>
          <v-tab href="#SalesREINFO"> Resueltos Info.</v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentItem">
          <v-tab-item :value="'DashboardCv'">
            <clc-sales-dasboard-detail
              :Area="dataDashboardAreaCv"
              :Lote="dataDashboardLoteCv"
              :Capital="dataDashboardCapitalCv"
              :ids="idsCv"
            />
          </v-tab-item>
          <v-tab-item :value="'DashboardRe'">
            <clc-sales-dasboard-detail
              :Area="dataDashboardAreaRe"
              :Lote="dataDashboardLoteRe"
              :Capital="dataDashboardCapitalRe"
              :ids="idsRe"
            />
          </v-tab-item>
          <v-tab-item :value="'SalesCV'">
            <v-row>
              <v-spacer />
              <v-col cols="6">
                <v-text-field
                  v-model="searchVendidos"
                  append-icon="mdi-magnify"
                  label="Lote"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <gral-table
                  :headers="headersCV"
                  :items="itemsCV"
                  :columnsNotNumber="['Documento', 'Año Venta']"
                  :propsdinamic="{
                    search: searchVendidos,
                    class: 'fixed-third-column',
                  }"
                ></gral-table>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item :value="'SalesRE'">
            <v-row>
              <v-spacer />
              <v-col cols="6">
                <v-text-field
                  v-model="searchResueltos"
                  append-icon="mdi-magnify"
                  label="Lote"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <gral-table
                  :headers="headersRE"
                  :items="itemsRE"
                  :columnsNotNumber="['Documento', 'Año Venta']"
                  :propsdinamic="{
                    search: searchResueltos,
                    class: 'fixed-third-column',
                  }"
                ></gral-table>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="'SalesCVINFO'">
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <gral-table
                    :headers="headersCVInfo"
                    :items="itemsCVI"
                  ></gral-table>
                </v-col>
              </v-row>
            </v-col>
          </v-tab-item>
          <v-tab-item :value="'SalesREINFO'">
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <gral-table
                    :headers="headersREInfo"
                    :items="itemsREI"
                  ></gral-table>
                </v-col>
              </v-row>
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import _sClcService from "@/services/ReportClicks/ClcChargeMasive.js";
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import GralTable from "../GralTable.vue";
import ClcSalesDasboardDetail from "./Dashboard/ClcSalesDasboardDetail.vue";

export default {
  components: {
    ClcSalesDasboardDetail,
    GralTable,
  },

  data() {
    return {
      currentItem: "tab-Funciones",
      processing: false,
      dialogShow: false,
      messageProcessing: "",
      objMounth: null,
      txtYear: this.$fun.getYear(),
      report: {},
      disabledBtnDownload: true,

      itemsCV: [],
      itemsRE: [],
      itemsREI: [],
      itemsCVI: [],
      headersCV: [],
      headersCVInfo: [],
      headersRE: [],
      headersREInfo: [],
      groupCv: [],
      groupRe: [],
      dataDashboard: [],
      dataDashboardAreaCv: [],
      dataDashboardLoteCv: [],
      dataDashboardCapitalCv: [],

      dataDashboardAreaRe: [],
      dataDashboardLoteRe: [],
      dataDashboardCapitalRe: [],

      show: false,
      openModalGraph: false,
      dataDashboardCv1: [],
      dataDashboardCv2: [],

      itemsX: [],
      itemsY: [],
      itemsX1: [],
      itemsY2: [],
      txtMonth: null,
      idsRe: [4, 5, 6],
      idsCv: [1, 2, 3],
      searchVendidos: "",
      searchResueltos: "",
    };
  },
  mounted() {},

  methods: {
    run() {
      this.loadInfoCv();
      this.loadDetailCv();
      this.loadInfoRe();
      this.loadDetailRE();
      this.disabledBtnDownload = false;
    },

    loadDetailCv() {
      const report = {};
      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcSalesDetailCV_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.processing = false;
            this.itemsCV = resp.data;
            this.headersCV = Object.keys(resp.data[0]).map((item) => ({
              text: item,
              value: item,
              width: "120",
              sortable: false,
              filterable:
                item === "Lote" || item === "Cuadrante" ? true : false,
            }));
          }
        })
        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
    },
    loadDetailRE() {
      const report = {};
      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcSalesDetailRE_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.processing = false;
            this.itemsRE = resp.data;
            this.headersRE = Object.keys(resp.data[0]).map((item) => ({
              text: item,
              value: item,
              width: "120",
              sortable: false,
              filterable:
                item === "Lote" || item === "Cuadrante" ? true : false,
            }));
          }
        })
        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
    },
    loadInfoCv() {
      const report = {};
      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcSalesInfoCv_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.processing = false;
            this.itemsCVI = resp.data;
            this.headersCVInfo = Object.keys(resp.data[0]).map((item) => ({
              text: item,
              value: item,
              width: "120",
              sortable: false,
            }));
            const tmpTotals = resp.data
              .filter((item) => item.Cuadrante !== "Total General")
              .filter((item) => item.Proyecto === null);

            this.dataDashboardLoteCv = tmpTotals.map((item) => ({
              name: item.Cuadrante,
              y: item.Lotes,
            }));

            this.dataDashboardAreaCv = tmpTotals.map((item) => ({
              name: item.Cuadrante,
              y: item.Area,
            }));

            this.dataDashboardCapitalCv = tmpTotals.map((item) => ({
              name: item.Cuadrante,
              y: item.Capital,
            }));
          }
        })
        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
    },
    loadInfoRe() {
      const report = {};
      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcSalesInfoRe_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.processing = false;
            this.itemsREI = resp.data;
            this.headersREInfo = Object.keys(resp.data[0]).map((item) => ({
              text: item,
              value: item,
              width: "120",
              sortable: false,
            }));
            const tmpTotals = resp.data
              .filter((item) => item.Cuadrante !== "Total General")
              .filter((item) => item.Proyecto === null);

            this.dataDashboardLoteRe = tmpTotals.map((item) => ({
              name: item.Cuadrante,
              y: item.Lotes,
            }));

            this.dataDashboardAreaRe = tmpTotals.map((item) => ({
              name: item.Cuadrante,
              y: item.Area,
            }));

            this.dataDashboardCapitalRe = tmpTotals.map((item) => ({
              name: item.Cuadrante,
              y: item.Capital,
            }));
          }
        })
        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
    },
    downloadOne() {
      const SheetNames = [
        "Ventas CV",
        "Ventas RE",
        "Ventas Info CV",
        "Ventas Info RE",
      ];
      const DataTables = [
        this.itemsCV,
        this.itemsRE,
        this.itemsCVI,
        this.itemsREI,
      ];
      const dataSend = { DataTables, SheetNames };

      this.processing = true;
      _sQryConfigurationService
        .downloadexcelrc(dataSend, this.$fun.getUserID())
        .then(
          (r) => {
            if (r.status == 200) {
              this.processing = false;

              this.$fun.downloadFile(
                r.data,
                this.$const.TypeFile.EXCEL,
                "VentasDetalle "+this.objMounth.DedDescription
              );
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
</style>
