<template>
  <div>
    <v-container class="px-0">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" lg="12" md="12">
            <high-charts-drill-bar
              :id="ids[0]"
              :items="Lote"
              :visibleSubTitle="true"
              :subtitle="'LOTES'"
              :name="'Proyecto'"
              :valueSuffix="'UNID'"
              :number="true"
            />
          </v-col>
          <v-col cols="12" lg="12" md="12">
            <high-charts-drill-bar
              :id="ids[1]"
              :items="Area"
              :visibleSubTitle="true"
              :subtitle="'Area'"
              :name="'Proyecto'"
              :valueSuffix="'M2'"
              :number="true"
              :thousandsSep="true"
            />
          </v-col>
          <v-col cols="12" lg="12" md="12">
            <high-charts-drill-bar
              :id="ids[2]"
              :items="Capital"
              :visibleSubTitle="true"
              :subtitle="'Capital'"
              :name="'Proyecto'"
              :valueSuffix="'USD'"
              :number="true"
              :thousandsSep="true"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import HighChartsDrillBar from "@/components/Utils/HighCharts/HighChartsDrillBar.vue";
export default {
  props: {
    Area: {
      type: Array,
      default: [],
    },
    Lote: {
      type: Array,
      default: [],
    },
    Capital: {
      type: Array,
      default: [],
    },
    ids: {
      type: Array,
      default: [],
    },
  },
  components: {
    HighChartsDrillBar,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
</style>
